<template>
    <div class="table-page">
        <el-breadcrumb separator="/" style="margin-bottom: 20px">
            <el-breadcrumb-item :to="{ path: '/main/dashboard' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>门诊排班</el-breadcrumb-item>
            <el-breadcrumb-item>周期性排班</el-breadcrumb-item>
        </el-breadcrumb>
        <el-form :inline="true" style="text-align: center">
            <el-form-item label="院区">
                <select-hospital v-model="search.hospital_id" size="small"/>
            </el-form-item>
            <el-form-item label="科室">
                <select-department v-model="search.department_id" :hospital_id="search.hospital_id" size="small"/>
            </el-form-item>
            <el-form-item>
                <el-input v-model="search.keywords" size="small" placeholder="关键词" clearable></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" plain size="small" @click="handleSearch">检索</el-button>
            </el-form-item>
        </el-form>
        <div style="margin-bottom:10px">
            <router-link to="/main/worksheet/form">
                <el-button type="primary" size="small" icon="el-icon-plus">新增排班</el-button>
            </router-link>
        </div>
        <div class="page-main">
            <el-table stripe :data="table.data" style="width: 100%" v-loading="loading" border height="100%">
                <el-table-column type="expand">
                    <template slot-scope="props">
                        <div style="margin-left:50px;line-height: 30px;">
                            分时设置
                            <div class="expend-times">
                                <div class="time" v-for="t in props.row.times" :key="t.ID">{{t.time}}({{t.count}})</div>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center"
                                 v-for="(v, idx) in table.columns"
                                 :prop="v.field"
                                 :label="v.title"
                                 :width="v.width"
                                 :formatter="v.formatter"
                                 :key="idx">
                </el-table-column>
                <el-table-column align="center" label="操作" width="180" fixed="right">
                    <template slot-scope="scope">
                        <el-button @click="handleUpdate(scope.row)" size="small" type="warning"
                                   icon="el-icon-edit"></el-button>
                        <el-popover style="margin-left: 10px" :ref="`popover-${scope.$index}`" placement="top"
                                    width="180">
                            <p>确定删除[{{scope.row.doctor.name}}]的记录吗？</p>
                            <div>
                                <el-button type="danger" size="mini" @click="handleDel(scope.$index, scope.row)">确定
                                </el-button>
                                <el-button size="mini" type="default"
                                           @click="$refs[`popover-${scope.$index}`].doClose()">取消
                                </el-button>
                            </div>
                            <el-button size="small" type="danger" slot="reference" icon="el-icon-delete"></el-button>
                        </el-popover>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="page-footer">
            <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[10, 20, 30, 50]"
                           :total="table.total" :page-size="table.pageSize"
                           @current-change="handleCurrentChange"
                           @size-change="handleSizeChange"/>
        </div>
    </div>
</template>


<script>
    export default {
        data() {
            function weekdayFormatter(t) {
                const wds = ['周日', '周一', '周二', '周三', '周四', '周五', '周六']
                let result = ''
                t.weekday.forEach((w) => {
                    result += wds[w] + ' '
                })
                return result
            }

            return {
                loading: false,
                table: {
                    columns: [
                        {title: '院区', field: 'hospital.name', width: '160'},
                        {title: '类型', field: 'type.name', width: '100'},
                        {title: '科室', field: 'department.name', width: '100'},
                        {title: '医生', field: 'doctor.name', width: '220'},
                        {title: '排班', field: 'weekday', width: '270', formatter: weekdayFormatter},
                        {title: '备注', field: 'desc', width: ''},
                    ],
                    data: [],
                    total: 0,
                    page: 1,
                    pageSize: 20
                },
                search: {},
            }
        },
        methods: {
            handleCurrentChange(e) {
                this.table.page = e
                this.getData()
            },
            handleSizeChange(e) {
                this.table.pageSize = e
                this.getData()
            },
            async handleDel(index, row) {
                this.$refs[`popover-${index}`].doClose()
                const resp = await this.$http.delete(`/mingde/worksheet/${row.id}`)
                if (resp.data.code == 200)
                    this.$message.success("删除成功")
                else
                    this.$message.error(resp.data.msg)
                this.getData()
            },
            handleUpdate(row) {
                this.$router.push(`/main/worksheet/form?id=${row.id}`)
            },
            async getData() {
                this.loading = true
                let params = this.search
                params['page'] = this.table.page
                params['size'] = this.table.pageSize
                const resp = await this.$http.get('/mingde/worksheet/', {params: params})
                this.table.data = resp.data.data.data|| []
                this.table.total = resp.data.data.total
                this.loading = false
            },
            handleSearch() {
                this.table.page = 1
                this.getData()
            },
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>
    .search {
        text-align: center;
    }

    .expend-times {
        display: flex;
        display: -webkit-flex;
        justify-content: flex-start;
        flex-direction: row;
        flex-wrap: wrap;

        .time {
            width: 200px;
        }
    }
</style>
